import { extendObservable, toJS } from "mobx"
import http from "../utils/http"
import { minByProp, maxByProp, halfDay } from "../utils/common"
import startOfDay from "date-fns/startOfDay"
import addDays from "date-fns/addDays"

export class HouseStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      house: {
        owner_user_id: 0,
        owner_user: {},
        name: "",
        short_detail: "",
        long_detail: "",
        room_detail: "",
        term_and_condition: "",
        nearly_restaurant: "",
        nearly_landmark: "",
        check_in: undefined,
        check_out: undefined,
        min_person: 0,
        max_person: 0,
        status: "",
        rank_score: 0,
        insurance_price: 0,
        house_images: [],
        house_icons: [],
        locations: [],
        price_detail: {},
        prices: [],
        beds:0,
        bedrooms:0,
        bathrooms:0,
      },
      houses: [],
    })
  }

  isLoading() {
    return toJS(this.loading)
  }

  getHouseJS() {
    return toJS(this.house)
  }

  getHousesJS() {
    return toJS(this.houses)
  }

  async fetchHouses() {
    try {
      const query = {
        isActiveOnly: true,
      }
      const response = await http.get("/houses", query)
      if (response) {
        this.houses = response || []
      }
    } catch (error) {
      console.log(error)
    }
  }

  async fetchHouse(id) {
    try {
      const search = JSON.parse(localStorage.getItem("search"))
      const query = {
        show_price: true,
        price_start_date: search
          ? search.checkIn
          : halfDay(startOfDay(new Date()), "start"),
        price_end_date: search
          ? search.checkOut
          : halfDay(addDays(startOfDay(new Date()), 1), "end"),
      }
      const response = await http.get(`/houses/${id}`, query)
      if (response) {
        const houses = response || []
        let price_detail = {}
        price_detail.price = maxByProp(houses.prices, "price")
        price_detail.special_price = minByProp(houses.prices, "special_price")
        price_detail.price_per_person = maxByProp(
          houses.prices,
          "price_per_person"
        )
        houses.price_detail = price_detail
        this.house = houses
      }
    } catch (error) {
      console.log(error)
    }

    // this.house = {
    //   id: 12,
    //   ownerUserId: 1,
    //   ownerUser: {},
    //   name: "BEST HAVEN POOL VILLA KHAO YAI",
    //   shortDetail: `"เบสท์ ฮาเว่น" พูลวิลล่าส่วนตัวบริเวณเขาใหญ่สไตล์ Loft & Luxury จัดเต็มไปกับสิ่งอำนวยความสะดวกครบๆ ทั้งสระว่ายน้ำส่วนตัว, คาราโอเกะ, โต๊ะพูล และเตา BBQ แถมได้วิวภูเขาและธรรมชาติแบบชิลๆไปอีก`,
    //   longDetail:
    //     "<p>⭕ พักสูงสุดได้ 15 ท่าน</p><p>⭕ เสริมท่านละ 500 บาท (พร้อมที่นอนเสริม)</p><p>⭕ เด็กไม่เกิน 10 ปีฟรี 2 ท่าน เกินคิด 300 บาท /ท่าน</p><p>⭕ มัดจำ 50% / ประกันบ้าน 5,000 บาท</p>",
    //   termAndCondition:
    //     "<ul><li>ห้ามสูบบุหรี่ภายในตัวบ้านพัก</li><li>งดส่งเสียงดังหลัง 22:00 น. (ภายนอกบ้านพัก)</li><li>ทำความสะอาดอุปกรณ์ครัว และภาชนะก่อนเช็คเอาท์ หากไม่สะดวกคิดค่าบริการ 500 บาท</li><li>เช็คเอาท์เลทปรับชั่วโมงละ 1,000 บาท</li><li>ห้ามเข้าพักเกินจำนวนที่จอง + กำหนดไว้</li></ul>",
    //   nearlyRestaurant:
    //     "<ul><li>ธาราคาเฟ่ เขาใหญ่ - 5.5 กม.</li><li>E.A.T. เขาใหญ่ - 7.5 กม.</li><li>Ribs Mann - 8.1 กม.</li><li>ร้านลูกไก่ - 8.4 กม.</li><li>ยุ้งข้าว เขาใหญ่ - 8.5 กม.</li><li>Midwinter Green - 10 กม.</li><li>Aomkord Cafe - 15 กม.</li><li>The Mew - 16 กม.</li><li>Chocolate Factory - 17 กม.</li><li>ครัวเขาใหญ่ - 18 กม.</li></ul>",
    //   nearlyLandmark:
    //     "<ul><li>ปากซอยมีร้านขายของชำ</li><li>ร้านสะดวกซื้อ 7-11 - 10 นาที</li><li>แมคโคร, โลตัส - 13 กม.</li><li>โบนันซ่า เขาใหญ่ - 7.2 กม.</li><li>ทุ่งทานตะวัน ไร่มณีศร - 9.7 กม.</li><li>น้ำผุด น้ำพุธรรมชาติบ้านท่าช้าง - 14 กม.</li><li>ฟาร์มโชคชัย - 17 กม.</li><li>ปาลิโอ เขาใหญ่ - 21 กม.</li><li>สวนสนุกซีนิคอลเวิลด์ - 23 กม.</li><li>อุทยานแห่งชาติเขาใหญ่ - 27 กม.</li></ul>",
    //   checkIn: "14:00:00",
    //   checkOut: "12:00:00",
    //   minPerson: 1,
    //   maxPerson: 2,
    //   status: "PUBLISH",
    //   rankScore: 1,
    //   insurancePrice: 1000,
    //   houseImages: [
    //     {
    //       id: 1,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/350/0/iTopPlus228920465697.webp",
    //       isHighlight: 1,
    //       sequence: 1,
    //     },
    //     {
    //       id: 2,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/710/0/iTopPlus1038582562936.webp",
    //       isHighlight: 0,
    //       sequence: 2,
    //     },
    //     {
    //       id: 3,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/710/0/iTopPlus719279602625.webp",
    //       isHighlight: 0,
    //       sequence: 3,
    //     },
    //     {
    //       id: 4,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/710/0/iTopPlus1328824850617.webp",
    //       isHighlight: 0,
    //       sequence: 4,
    //     },
    //     {
    //       id: 5,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/710/0/iTopPlus538628474002.webp",
    //       isHighlight: 0,
    //       sequence: 5,
    //     },
    //     {
    //       id: 6,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/710/0/iTopPlus661381722900.webp",
    //       isHighlight: 0,
    //       sequence: 6,
    //     },
    //     {
    //       id: 7,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/710/0/iTopPlus135774427413.webp",
    //       isHighlight: 0,
    //       sequence: 7,
    //     },
    //     {
    //       id: 8,
    //       houseId: 12,
    //       url:
    //         "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/710/0/iTopPlus462534725813.webp",
    //       isHighlight: 0,
    //       sequence: 8,
    //     },
    //   ],
    //   houseIcons: [
    //     {
    //       id: 1,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus831874522880.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "บ้านพักพูลวิลล่า",
    //       sequence: 1,
    //     },
    //     {
    //       id: 2,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus798775379304.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "พักได้สูงสุด 7 ท่าน",
    //       sequence: 2,
    //     },
    //     {
    //       id: 3,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus837439178157.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "2 ห้องนอน",
    //       sequence: 3,
    //     },
    //     {
    //       id: 4,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus1012324068389.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "1 ห้องน้ำ",
    //       sequence: 4,
    //     },
    //     {
    //       id: 5,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus1420450878894.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "สระว่ายน้ำระบบเกลือ",
    //       sequence: 5,
    //     },
    //     {
    //       id: 6,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus1260430940851.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "สไลเดอร์ขนาดใหญ่",
    //       sequence: 6,
    //     },
    //     {
    //       id: 7,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus1047297485064.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "เตา BBQ (300 บาท/เตา)",
    //       sequence: 7,
    //     },
    //     {
    //       id: 8,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus564540062600.webp",
    //         description: "",
    //       },
    //       type: "DETAIL",
    //       description: "Free Breakfast",
    //       sequence: 8,
    //     },
    //     {
    //       id: 9,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus1420450878894.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "สระส่วนตัวระบบเกลือ",
    //       sequence: 1,
    //     },
    //     {
    //       id: 10,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus373537885916.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "ระบบจากุซซี่ 3 หัวในสระ ไฟสระ",
    //       sequence: 2,
    //     },
    //     {
    //       id: 11,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus591577378372.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "ห่วงยางแฟนซี",
    //       sequence: 3,
    //     },
    //     {
    //       id: 12,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus820947971488.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "ที่นั่งชิลระเบียงหน้าบ้าน",
    //       sequence: 4,
    //     },
    //     {
    //       id: 13,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus101584332479.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "เบาะบีนแบคนั่งชิลระเบียงชั้น 2",
    //       sequence: 5,
    //     },
    //     {
    //       id: 14,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus1223413579298.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "Smart TV 50 นิ้ว",
    //       sequence: 6,
    //     },
    //     {
    //       id: 15,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus511963929245.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "ชุดคาราโอเกะ",
    //       sequence: 7,
    //     },
    //     {
    //       id: 16,
    //       icon: {
    //         url:
    //           "https://itp1.itopfile.com/ImageServer/c92e01ec59a8ddac/0/0/iTopPlus570623057585.webp",
    //         description: "",
    //       },
    //       type: "FACILITY",
    //       description: "โต๊ะพูล",
    //       sequence: 8,
    //     },
    //   ],
    //   locations: [],
    // }
  }
}

const houseStore = new HouseStore()
export default houseStore
