import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { Navbar, Nav } from "react-bootstrap";
import { Button, IconButton, Popper, MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import auth from "../utils/auth";
import LoginModal from "./Modal/LoginModal";
import OtpModal from "./Modal/OtpModal";
import { authGoogle,provider } from "./Modal/GoogleConfig";
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*": {
      fontFamily: "Kanit, sans-serif !important",
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    backgroundColor: "#1a1a1a",
    color: "#fff",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  link: {
    fontSize: "17px",
    margin: theme.spacing(1, 1.5),
    color: "#fff !important",
    "&:hover": {
      color: "#e2c366 !important",
      boxShadow: "0 2px 0 0 #e2c366",
    },
  },
  loginButton: {
    marginLeft: "auto",
    backgroundColor: "#e2c366",
    color: "#1a1a1a",
    "&:hover": {
      backgroundColor: "#d1ab3c",
    },
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "@media (max-width: 991px)": { // เปลี่ยนจาก theme.breakpoints เป็น Media Query
      width: "100%",
      marginLeft: 0,
      justifyContent: "center",
      marginBottom: "20px",
    },
  },
  modalContent: {
    padding: "30px",
    borderRadius: "8px",
    textAlign: "center",
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "relative", // for positioning the close button
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: "#888",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    marginBottom: "10px",
  },
  continueButton: {
    width: "100%",
    backgroundColor: "#e2c366",
    color: "#1a1a1a",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    marginTop: "10px",
  },
  socialButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    marginBottom: "10px",
    border: "1px solid #ddd",
    textTransform: "none",
    color: "#555",
    position: "relative",
  },
  icon: {
    position: "absolute",
    left: "10px",
  },
  facebookButton: {
    color: "#3b5998",
  },
  googleButton: {
    color: "#db4437",
  },
  termsText: {
    fontSize: "12px",
    color: "#888",
    marginTop: "20px",
  },
  resendText: {
    marginTop: "10px",
    fontSize: "14px",
    color: "#888",
  },
  otpDescription: {
    marginBottom: "20px",
    fontSize: "14px",
  },
  changeContact: {
    fontSize: "14px",
    color: "#888",
    marginTop: "15px",
    textAlign: "center",
  },
  otpValidity: {
    fontSize: "14px",
    color: "#888",
    marginTop: "10px",
    marginBottom: "20px",
    textAlign: "center",
  },
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 1500,
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    minWidth: "200px",
    padding: "10px 0",
  },
  menuItem: {
    padding: "12px 16px",
    fontSize: "14px",
    color: "#333",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f0f0f0", // สีพื้นหลังเมื่อ hover
      color: "#e2c366", // สีตัวอักษรเมื่อ hover
    },
    "&:not(:last-child)": {
      borderBottom: "1px solid #ddd", // เส้นแบ่งระหว่างรายการ
    },
  },
  logoutItem: {
    padding: "12px 16px",
    fontSize: "14px",
    color: "#e2c366",
    fontWeight: 500,
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f9f9f9", // สีพื้นหลังเมื่อ hover
    },
  },
}));

const Header = ({ siteTitle, onOpenLoginModal }) => {
  const classes = useStyles();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [ref, setRef] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [requestToken, setRequestToken] = useState(null);
  const isMobile = useMediaQuery("(max-width:991px)");

  useEffect(() => {
    if (otpModalIsOpen && countdown > 0) {
      const timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
    if (onOpenLoginModal) {
      onOpenLoginModal((redirect) => {
        setRedirectUrl(redirect); // เก็บ redirect URL
        setModalIsOpen(true); // เปิด modal
      });
    }
    if (auth.isAuthenticated()) {
      setIsAuthenticated(true);
    }
  }, [otpModalIsOpen, countdown, onOpenLoginModal]);

  const handleClickLoginFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authGoogle, provider)
    .then((result) => {
      const user = result.user;
      console.log("Facebook Login Success:", user);
    })
    .catch((error) => {
      console.error("Facebook Login Error:", error);
    });
  }

  const handleClickLoginGoogle = () => {
    signInWithPopup(authGoogle, provider).then( async (data) => {
      try {
        const response = await auth.loginGoogle(data.user.accessToken);
        if (response.data.task === "login") {
          auth.setToken(response.data.token);
          auth.setUserInfo(response.data.info);
          if (redirectUrl) {
            window.location.href = redirectUrl; // Redirect หลังจาก login สำเร็จ
          }else{
            window.location.reload();
          }
        }
      } catch (error) {
        // console.log('error---->', error)
        alert('ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อแอดมิน')
      }
    })
  }

  const handleSubmit = async () => {
    if (!emailOrPhone) return setError("กรุณากรอกเบอร์โทรหรืออีเมล");
    try {
      setIsSubmitting(true);
      const response = await auth.sendOTP(emailOrPhone);
      setRef(response.data.ref);
      setRequestToken(response.data.request_token);
      setModalIsOpen(false);
      setOtpModalIsOpen(true);
    } catch {
      setError("เกิดข้อผิดพลาด");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) return setError("กรุณากรอก OTP");
    try {
      setIsSubmitting(true);
      const response = await auth.verifyOTP(emailOrPhone, otp, ref, requestToken);
      if (response.data.task === "login") {
        auth.setToken(response.data.token);
        auth.setUserInfo(response.data.info);
        if (redirectUrl) {
          window.location.href = redirectUrl; // Redirect หลังจาก login สำเร็จ
        }else{
          window.location.reload();
        }
      }
    } catch {
      setError("OTP ไม่ถูกต้อง");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogout = () => {
    auth.clear();
    window.location.reload();
  };

  const handleResendOtp = async () => {
    setIsSubmitting(true);
    try {
      // const response = await http.post('/customers/login', { email_or_phone: emailOrPhone });
      const response = await auth.sendOTP(emailOrPhone);
      setRef(response.data.ref);
      setRequestToken(response.data.request_token);
      setCountdown(60); // รีเซ็ตเวลา
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("เกิดข้อผิดพลาดในการส่ง OTP");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleToggleProfileMenu = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const renderProfileMenu = () => (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} transition placement="bottom-end" className={classes.popper}>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <MenuList>
              <MenuItem className={classes.menuItem} onClick={() => window.location.href = "/Profile"}>
                แก้ไขข้อมูลส่วนตัว
              </MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => window.location.href = "/Bookings"}>
                การจองของฉัน
              </MenuItem>
              <MenuItem className={classes.logoutItem} onClick={handleLogout}>
                ออกจากระบบ
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
    </Popper>
  );

  const renderProfileMenuMobile = () => (
    <>
    <Nav className="mr-auto navbar-dark">
            <Nav.Link className={classes.link} href="/Profile">
            แก้ไขข้อมูลส่วนตัว
            </Nav.Link>
            <Nav.Link className={classes.link} href="/Bookings">
            การจองของฉัน
            </Nav.Link>
    </Nav>
    <Button className={classes.loginButton} onClick={handleLogout}>
      <FontAwesomeIcon icon={faSignInAlt} id="signin-icon" style={{ marginRight: "10px" }} /> ออกจากระบบ
    </Button>
    </>

  );

  return (
    <>
      <CssBaseline />
      <Navbar className={classes.appBar} expand="lg" variant="dark">
        <Navbar.Brand href="/">
          <img
            height="75px"
            src={`${process.env.AWS_S3_URL}/assets/besthaven-white.png`}
            alt={siteTitle}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto navbar-dark">
            <Nav.Link className={classes.link} href="/">
              หน้าแรก
            </Nav.Link>
            <Nav.Link className={classes.link} href="/AboutUs">
              เกี่ยวกับเรา
            </Nav.Link>
            {/* <Nav.Link className={classes.link} href="/CustomerReview">
              รีวิวจริงจากลูกค้า
            </Nav.Link> */}
            <Nav.Link className={classes.link} href="/ContactUs">
              ติดต่อเรา
            </Nav.Link>
          </Nav>
          {isAuthenticated ? (
            isMobile ? (
              renderProfileMenuMobile() // แสดงเป็น list สำหรับ mobile
            ) : (
              <>
                <IconButton className={classes.loginButton} onClick={handleToggleProfileMenu}>
                  <FontAwesomeIcon icon={faUserAlt} id="user-icon" />
                </IconButton>
                {renderProfileMenu()}
              </>
            )
          ) : (
            <Button className={classes.loginButton} onClick={() => setModalIsOpen(true)}>
              <FontAwesomeIcon icon={faSignInAlt} id="signin-icon" style={{ marginRight: "10px" }} /> เข้าสู่ระบบ
            </Button>
          )}
        </Navbar.Collapse>
      </Navbar>
      <LoginModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        emailOrPhone={emailOrPhone}
        setEmailOrPhone={setEmailOrPhone}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        error={error}
        classes={classes}
        handleGoogleLogin={handleClickLoginGoogle} 
        handleFacebookLogin={handleClickLoginFacebook}
      />
      <OtpModal
        isOpen={otpModalIsOpen}
        onRequestClose={() => setOtpModalIsOpen(false)}
        emailOrPhone={emailOrPhone}
        otp={otp}
        setOtp={setOtp}
        error={error}
        isSubmitting={isSubmitting}
        handleVerifyOtp={handleVerifyOtp}
        countdown={countdown}
        handleResendOtp={handleResendOtp}
        onChangeContact={() => {
          setOtpModalIsOpen(false);
          setModalIsOpen(true);
        }}
        classes={classes}
      />
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  onOpenLoginModal: PropTypes.func,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;