export default {
  authentication_failed: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
  access_denied: 'ไม่มีสิทธิ์ในการเข้าถึงข้อมูล',
  validation_failed: 'กรุณาระบุข้อมูลให้ถูกต้องและครบถ้วน',
  token_invalid: 'การเข้าสู่ระบบหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  booking_fully : 'ขออภัยที่พักในวันที่ท่านจองเต็มแล้ว',
  unknown_error: 'เกิดข้อผิดพลาด',
  data_not_found: 'ไม่พบข้อมูล',
  data_exist: 'มีข้อมูลนี้ในระบบแล้ว',
  username_exist: 'มีชื่อผู้ใช้นี้อยู่ในระบบแล้ว',
  mobile_exist: 'มีเบอร์โทรศัพท์นี้ในระบบแล้ว',
  parameter_required: 'กรุณาระบุข้อมูลให้ถูกต้อง',
  parameter_invalid: 'ข้อมูลไม่ถูกต้อง',
  username_password_required: 'กรุณาระบุชื่อผู้ใช้และรหัสผ่าน',
  email_failed: 'ไม่สามารถส่งอีเมลได้',
  customer_exist: 'เบอร์โทรศัพท์ซ้ำในระบบ',
  internal_error: 'เกิดข้อผิดพลาดภายในระบบ',
  customer_not_found: 'ไม่พบผู้ใช้งาน',
  otp_not_found: 'ไม่พบ OTP',
  otp_expired: 'OTP หมดอายุ',
  otp_already_used: 'OTP ถูกใช้ไปแล้ว',
  otp_send_failed: 'ไม่สามารถส่ง OTP ได้',
  otp_invalid: 'OTP ไม่ถูกต้องหรือหมดอายุ',
  booking_pending_limit: 'จำนวนการจองห้องพักอยู่ในสถานะยังไม่จ่ายเงินเกินกว่าที่กำหนด',
  booking_fully: 'ขออภัยที่พักในวันที่ท่านจองเต็มแล้ว',
  google_login_failed: 'ไม่สามารถเข้าสู่ระบบด้วย Google ได้',
};